<template>
  <div>
    <div class="tabled" style="margin-bottom: 10%;">
      <div class="tabled-cell Nunito">
        <h1 class="welcomeText mainTextColor">{{ $t("home.name") }}</h1>
        <h1 class="is-size-3" v-html='$t("home.i3", [this.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), this.guilds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")])'></h1>
        <h5 class="is-size-5">{{ $t("home.i5") }}</h5>
      </div>
      <div class="hidden circles md:block">
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
      </div>
    </div>
    <Features />
  </div>
</template>

<script>
  import Features from '@/views/features.vue'
  export default {
    name: 'Home',
    components: {
      Features
    },
    data () {
      return {
        guilds: 500,
        users: 1000,
        musics: 0
      }
    },
    props: {
    },
    created () {
      if (this.$store.getters.admin.rick && this.$route.query.from !== 'login' && this.$route.query.from !== 'logout' && Math.floor(Math.random() * 101) === 69) {
        window.location = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&getRickRoledBy=GraceBot&YouGot69OutOfIdkSoManyNumber'
        return
      }
      this.$store.dispatch('getStats').then((result) => {
        if (result.data && result.data.code === 200 && !result.data.message) {
          this.guilds = result.data.data.info.guilds
          this.users = result.data.data.info.users
          this.musics = result.data.data.info.music
        } else {
          this.guilds = 500
          this.users = 1000
          this.musics = 0
        }
      })
    },
    methods: {}
  }
</script>

<style scoped>
.tabled {
  display: table;
  height: 35rem;
  margin: 0 auto;
}
.tabled-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.welcomeText {
  font-size: 3.5em;
  filter: blur(0) brightness(100%) contrast(100%) grayscale(0) hue-rotate(0) saturate(100%);
  font-weight: 700;
  line-height: 1;
}

</style>

<style scoped>

.circles {
  pointer-events: none;
  position: absolute;
  height: 57.5%;
  width: 100%;
  left: 0;
  top: 0;
}

.hidden {
  display: none;
}

.circles {
    pointer-events: none;
    position: absolute;
    height: 57.5%;
    width: 100%;
    left: 0;
    top: 0;
}
.circles div {
    border-radius: 100%;
    position: absolute;
    opacity: 0.5;
}
.circles div:first-child {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #6366f1;
    --stops: var(--from), var(--to, rgba(99, 102, 241, 0));
    --stops: var(--from), #4f46e5, var(--to, rgba(79, 70, 229, 0));
    --to: #4338ca;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 5vw;
    width: 5vw;
    left: 25vw;
    top: 7.5vw;
}
.circles div:nth-child(2) {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #10b981;
    --stops: var(--from), var(--to, rgba(16, 185, 129, 0));
    --stops: var(--from), #059669, var(--to, rgba(5, 150, 105, 0));
    --to: #047857;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 12.5vw;
    width: 12.5vw;
    left: 12.5vw;
    top: 12.5vw;
}
.circles div:nth-child(3) {

    background-image: linear-gradient(to top left, var(--stops));
    --from: #6b7280;
    --stops: var(--from), var(--to, rgba(107, 114, 128, 0));
    --stops: var(--from), #4b5563, var(--to, rgba(75, 85, 99, 0));
    --to: #374151;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 27.5vw;
    width: 27.5vw;
    left: -13vw;
    top: -13vw;
}
.circles div:nth-child(4) {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #f59e0b;
    --stops: var(--from), var(--to, rgba(245, 158, 11, 0));
    --stops: var(--from), #d97706, var(--to, rgba(217, 119, 6, 0));
    --to: #b45309;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 7.5vw;
    width: 7.5vw;
    right: 20vw;
    top: 10vw;
}
.circles div:nth-child(5) {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #ef4444;
    --stops: var(--from), var(--to, rgba(239, 68, 68, 0));
    --stops: var(--from), #dc2626, var(--to, rgba(220, 38, 38, 0));
    --to: #b91c1c;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 27.5vw;
    width: 27.5vw;
    bottom: -15vw;
    right: -17.5vw;
    z-index: 0;
}

@media (min-width: 768px) {
  .md\:block {
      display: block;
  }
}
</style>
